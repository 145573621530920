const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay:true,
      dotsClass: 'slick-dots slide-dots',
      arrows: false
    }


    export {settings}
