import {brioBKG, ftBKG, gameBKG, foguangBKG,geocover,pinchBKG,briolandingBKG,briopricingBKG} from './Image'
export const ProjectList = [
  {title: 'Placement Indicator', category:'Case Study, UX Design', app:'BRIOVR',
    caption:'Solve the problem of placing objects in 3D space using 2D editor',
    img: brioBKG, link:'/briovr'},

  {title: 'From Soul Food to Fair Trade', category:'Case Study, UX Design', app:'FairTrade',
    caption:'Bridging the disconnection between newcomers and kind hearted locals',
    img: ftBKG, link:'/fairtrade'},

  {title: 'Foguang Temple Touring Guide', category:'Case Study, UX Design, Development', app:'Foguang Temple',
    caption:'Telling the story behind the temple with a thunsand year history and the visitors',
    img: foguangBKG, link:'/foguang-temple'},

  {title: 'Paper Prototype for Quick Play Test', category:'Case Study, UX Design, Game Design, Paper Prototyping', app:'Break A Leg',
    caption:'A party game that brings fun to all the players',
    img: gameBKG, link:'/break-a-leg'},

  {title: 'Live the Moment and Leave the Moment There', category:'Product Design, Other', app:'Geogram',
      caption:'Live the Moment and Leave the Moment There',
      img: geocover, link:'/geogram'},

  {title: 'Add Characteristics to Elements', category:'Interaction Design, Animation, Other', app:'BRIOVR',
        caption:'A bit of animation brings delight and life to apps',
        img: brioBKG, link:'/animation'},

  {title: 'Pinch Discovery & VR Collaborate Space', category:'Coding, Development, Unity, Other', app:'PinchVR',
        caption:'A bit of animation brings delight and life to apps',
        img: pinchBKG, link:'/pinchvr'},

  {title: 'Iterations of the BRIOVR Pricing Page', category:'Design, Development, Other', app:'BRIOVR',
        caption:'A bit of animation brings delight and life to apps',
        img: briopricingBKG, link:'/briopricing'},

  {title: '2D Projects', category:'Design, Animation, Other', app:'Multiple',
        caption:'A bit of animation brings delight and life to apps',
        img: briolandingBKG, link:'/2d'}


]

//----------------------------------------------------


// {title: 'Know Who Is The Real User', category:'Case Study, UX Design, Development', app:'Jump In',
//   caption:'Telling the story behind the temple with a thunsand year history and the visitors',
//   img:foguangBKG, link:'/foguang-temple'},

// {title: 'Geogram', category:'UX Design', app:'Geogram',
//   caption:'Telling the story behind the temple with a thunsand year history and the visitors',
//   img: foguangBKG, link:'/foguang-temple'}


//----------------------------------------------
